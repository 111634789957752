<template>
  <div>
    <el-dialog
      :title="$t('UnitsPageUpdateErrorDialogTitle')"
      :visible.sync="localVisible"
      v-if="localData"
    >
      <div class="u-bottom-margin--2x">
        {{ localData.data.message }}
      </div>
      <el-table
        :data="localData.data.data.results"
        style="width: 100%"
        border
        stripe
        class="u-bottom-margin--2x"
      >
        <el-table-column
          prop="image"
          :label="$t('DistributerDetailsPageDetailsColumnTitle')"
        >
          <template slot-scope="scope">
            <div class="u-display-flex u-align-items--center">
              <div
                :class="
                  $store.state.isRTL
                    ? 'u-left-margin--2x '
                    : 'u-right-margin--2x'
                "
              >
                <img
                  width="50"
                  height="50"
                  style="object-fit: scale-down"
                  :src="scope.row.image"
                />
              </div>
              <div class="u-direction--rtl">
                <div class="u-font--medium">
                  {{ scope.row.name }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="closeDialogClicked">{{
          $t("GlobalOkBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { uniqBy } from "lodash";
export default {
  name: "UpdateUnitsErrorDialog",
  props: {
    responseObject: {
      type: Object,
      default: () => {
        return {
          data: {
            message: this.$t("GlobalDefaultMessage"),
            data: {
              results: [],
            },
          },
        };
      },
    },
  },
  data() {
    return {
      localVisible: false,
      localData: {
        data: {
          message: this.$t("GlobalDefaultMessage"),
          data: {
            results: [],
          },
        },
      },
    };
  },
  watch: {
    localVisible(value, oldValue) {
      if (value !== oldValue && value === false) {
        this.$emit("closeDialog");
      }
    },
    responseObject(newVal) {
      console.log("newVal", newVal);
      this.setLocalObject(newVal);
      if (newVal) {
        this.localVisible = true;
      } else {
        this.localVisible = false;
      }
    },
  },
  methods: {
    closeDialogClicked() {
      this.localVisible = false;
      this.$emit("closeDialog");
    },
    setLocalObject(value) {
      this.localData = value;
      if (value) {
        let uniqueProducts = uniqBy(value.data.data.results, "name");
        this.localData.data.data.results = uniqueProducts;
      }
    },
  },
};
</script>

<style></style>
