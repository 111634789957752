<template>
  <div>
    <!-- ************************************************************************************ -->
    <!-- Search and Button Area -->
    <!-- ************************************************************************************ -->
    <el-row :gutter="20" type="flex" justify="space-between">
      <el-col>
        <div class="u-display-flex u-justify-content--start">
          <div style="display: inline-block; width: 350px">
            <el-input
              :placeholder="$t('UnitsPageSearchFieldPlaceholder')"
              prefix-icon="el-icon-search"
              v-model="searchText"
              clearable
              @input="searchTextChanged"
            />
          </div>
        </div>
      </el-col>
      <el-col>
        <div class="u-display-flex u-justify-content--end">
          <el-button
            class="u-bottom-margin--1x"
            type="primary"
            @click="showCreateDialogFlag = true"
          >
            {{ $t("UnitsPageAddButtonLabel") }}
          </el-button>
        </div>
      </el-col>
    </el-row>
    <!-- ************************************************************************************ -->
    <!-- Table Area -->
    <!-- ************************************************************************************ -->
    <template>
      <el-table :data="unitsList" border stripe>
        <el-table-column prop="id" :label="$t('UnitsPageTableColumnUnitId')" />
        <el-table-column
          prop="name"
          :label="$t('UnitsPageTableColumnUnitName')"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.editFlag">
              {{ scope.row.name }}
            </span>
            <el-input v-else v-model="scope.row.name" size="small" />
          </template>
        </el-table-column>

        <el-table-column prop="operations" :label="$t('GlobalOperationsLabel')">
          <template slot-scope="props">
            <div v-if="!props.row.editFlag">
              <!-- <el-button
                type="danger"
                size="mini"
                @click="deleteButtonClicked(props.row)"
              >
                {{ $t("topDealsPageTableButtonLabelDelete") }}
              </el-button> -->
              <el-button
                type="success"
                size="mini"
                @click="editButtonClicked(props.row)"
              >
                {{ $t("GlobalsEditBtn") }}
              </el-button>
            </div>
            <div v-else>
              <el-button
                type="primary"
                size="mini"
                @click="saveButtonClicked(props.row)"
              >
                {{ $t("GlobalsSaveBtn") }}
              </el-button>
              <el-button
                type="danger"
                size="mini"
                @click="cancelEditClicked(props.row)"
              >
                {{ $t("GlobalsCancelBtn") }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- ************************************************************************************ -->
    <!-- Dialogs area -->
    <!-- ************************************************************************************ -->
    <CreateUnitsDialog
      v-model="showCreateDialogFlag"
      @closeDialog="closeCreateDialogClicked"
      @unitAdded="unitAdded"
    />
    <UpdateUnitsErrorDialog
      :responseObject="updateErrorResponseObject"
      @closeDialog="updateErrorResponseObject = null"
    />
  </div>
</template>

<script>
import { unitsService } from "@/services/units.service.js";
import CreateUnitsDialog from "./CreateUnitsDialog.vue";
import UpdateUnitsErrorDialog from "./UpdateUnitsErrorDialog.vue";
import { utils } from "@/utils";
export default {
  name: "UnitsPage",
  components: {
    CreateUnitsDialog,
    UpdateUnitsErrorDialog,
  },

  data() {
    return {
      unitsList: [],
      filters: {},
      showCreateDialogFlag: false,
      originalData: [],
      searchText: "",
      updateErrorResponseObject: null,
    };
  },
  computed: {
    currentPage() {
      let currentPageQuery = this.$route.query.page;
      return currentPageQuery ? parseInt(currentPageQuery) : 1;
    },
  },
  methods: {
    handleUnitUpdateError(responseObject) {
      this.updateErrorResponseObject = responseObject;
    },
    saveButtonClicked(clickedRow) {
      const originalRow = this.originalData.filter(
        (item) => item.id === clickedRow.id
      );
      this.$confirm(
        this.$t("UnitsPageUpdateCheckMessage", [
          originalRow[0].name,
          clickedRow.name,
        ]),
        "Warning",
        {
          confirmButtonText: this.$t("GlobalOkBtn"),
          cancelButtonText: this.$t("GlobalCancelBtn"),
          type: "warning",
        }
      ).then(async () => {
        try {
          const apiBody = { name: clickedRow.name };
          const apiParam = clickedRow.id;
          const response = await unitsService.updateSingleUnit(
            apiParam,
            apiBody
          );
          if (response && response.status) {
            if (response.status == 200) {
              this.handleUnitUpdateError(response);
            }
            if (response.status == 204) {
              utils.popupMessageWrapper(
                this.$t("UnitsPageUpdateSuccessMessage"),
                "success",
                1500
              );
              setTimeout(() => {
                this.getUnits(this.currentPage, this.filters);
              }, 1300);
            }
          }
        } catch (error) {
          console.log("unitUpdateError", error);
          const errorMessage = utils.errorMessageExtractor(error);
          utils.popupMessageWrapper(errorMessage, "error", 2000);
        }
      });
    },
    editButtonClicked(clickedRow) {
      clickedRow.editFlag = true;
    },
    cancelEditClicked(clickedRow) {
      const originalRow = this.originalData.filter(
        (item) => item.id === clickedRow.id
      );
      if (originalRow && originalRow.length > 0) {
        this.unitsList = this.unitsList.map((item) => {
          if (item.id == clickedRow.id) {
            item = JSON.parse(JSON.stringify(originalRow[0]));
            item.editFlag = false;
          }
          return item;
        });
      }
    },
    searchTextChanged(value) {
      if (value && value.length > 0) {
        const list = this.unitsList.filter((item) => {
          if (this.searchText.length > 0) {
            return item.name.toLowerCase().match(this.searchText.toLowerCase());
          }
          return true;
        });
        this.unitsList = list;
      } else {
        if (this.searchText === "")
          this.unitsList = JSON.parse(JSON.stringify(this.originalData));
      }
    },
    closeCreateDialogClicked() {
      this.showCreateDialogFlag = false;
    },
    async getUnits(page = 1, chosenFilters) {
      const filters = chosenFilters;
      try {
        const response = await unitsService.fetchUnits(page, filters);
        let responseData = response.data.data;
        responseData = responseData.map((item) => {
          return { ...item, editFlag: false };
        });
        this.originalData = JSON.parse(JSON.stringify(responseData));
        this.unitsList = responseData;
        return response;
      } catch (error) {
        console.log("getUnits error", error);
      }
    },
    async unitAdded() {
      this.showCreateDialogFlag = false;
      this.getUnits(this.currentPage, this.filters);
    },
  },
  async mounted() {
    this.getUnits(this.currentPage, this.filters);
  },
};
</script>

<style lang="scss" scoped></style>
