<template>
  <div>
    <el-dialog
      :title="$t('UnitsPageCreateDialogTitle')"
      :visible.sync="localVisible"
    >
      <div>
        <div
          class="u-bottom-margin--2x"
          v-for="(unitNameObject, index) in dataArray"
          :key="unitNameObject.key"
        >
          <el-row :gutter="20">
            <el-col :span="21">
              <el-input
                :placeholder="
                  $t('UnitsPageCreateDialogUnitNameFieldPlaceholder')
                "
                v-model="unitNameObject.name"
                clearable
              >
              </el-input>
            </el-col>
            <el-col :span="3">
              <el-button
                v-if="index == 0"
                type="success"
                icon="el-icon-plus"
                circle
                size="small"
                @click="addFieldClicked"
              ></el-button>
              <el-button
                v-else
                type="danger"
                icon="el-icon-minus"
                circle
                size="small"
                @click="removeFieldClicked(unitNameObject.key)"
              ></el-button>
            </el-col>
          </el-row>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="localVisible = false">{{
          $t("GlobalsCancelBtn")
        }}</el-button>
        <el-button type="success" @click="createUnits">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { unitsService } from "@/services/units.service.js";
import { utils } from "@/utils";

export default {
  name: "CreateUnitsDialog",
  props: ["value"],
  data() {
    return {
      localVisible: false,
      dataArray: [
        {
          key: 1,
          name: null,
        },
      ],
    };
  },
  watch: {
    localVisible(value, oldValue) {
      if (value !== oldValue) {
        this.$emit("input", value);
      }
    },
    value(newVal) {
      this.localVisible = newVal;
      if (newVal === true) {
        this.dataArray = [
          {
            key: 1,
            name: "",
          },
        ];
      }
    },
  },
  created() {
    this.localVisible = this.value;
  },
  methods: {
    addFieldClicked() {
      let newObject = {
        key: new Date().getTime(),
        name: "",
      };
      this.dataArray.push(newObject);
    },
    removeFieldClicked(objectKey) {
      const newArray = this.dataArray.filter((singleRow) => {
        return objectKey != singleRow.key;
      });
      this.dataArray = JSON.parse(JSON.stringify(newArray));
    },
    async createUnits() {
      try {
        let validationFlag = false;
        let unitsNamesArray = [];
        this.dataArray.forEach((item) => {
          if (item.name.length == 0) {
            validationFlag = true;
          } else {
            unitsNamesArray.push({
              name: item.name,
            });
          }
        });
        if (validationFlag) {
          utils.popupMessageWrapper(
            this.$t("UnitsPageCreateDialogValidationMessage"),
            "error",
            1500
          );
          return;
        }
        let apiObject = { units: unitsNamesArray };
        const response = await unitsService.createNewUnits(apiObject);
        if (response && response.status == 204) {
          utils.popupMessageWrapper(
            this.$t("UnitsPageCreateDialogCreateSuccesMessage"),
            "success",
            1500
          );
          setTimeout(() => {
            this.$emit("unitAdded");
          }, 1300);
        }
      } catch (error) {
        console.log("creatUnitError", error);
        const errorMessage = utils.errorMessageExtractor(error);
        utils.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },
  },
};
</script>

<style></style>
