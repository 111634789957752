import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function fetchUnits(page = 1, filters) {
  return $http.get(`/admin/unit`, {
    params: { page, filter: filters },
    headers: authHeader(),
  });
}

function createNewUnits(requestBody) {
  return $http.post(`/admin/unit/bulk`, requestBody, {
    headers: authHeader(),
  });
}

function updateSingleUnit(unitId, requestBody) {
  return $http.patch(`/admin/unit/${unitId}`, requestBody, {
    headers: authHeader(),
  });
}
export const unitsService = {
  fetchUnits,
  createNewUnits,
  updateSingleUnit,
};
